
















import { InputSetups } from '@/mixins/input-setups'

import SwitchInput from 'piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue'

import { Component, Mixins, VModel } from 'vue-property-decorator'
import { BooleanCondition } from "../BooleanCondition/BooleanCondition";

@Component({
  components: {
    SwitchInput
  }
})
export default class BooleanConditionView extends Mixins<InputSetups>(InputSetups) {
  @VModel() model!:BooleanCondition
}
